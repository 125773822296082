<template>
  <div id="c-body">
    <CBanner :bannerCont="bannerCont"/>
    <div id="content">
      <p style="
        text-align: center;    
        font-size: 22px;    
        line-height: 50px;"
      >
        资质认证
      </p>
      <h1>1. 软件著作权</h1>
      <div class='immp-pic'>
        <div class="immp-item">
          <img :src="require('../../../assets/10C685D0A2351A8E6E480306A72_0F5C48F8_43E41.jpg')">
          <img :src="require('../../../assets/37A7343943D537A03FF30ADADE9_A6716D19_3DC9C.jpg')">
        </div>
        <div class="immp-item">
          <img :src="require('../../../assets/29638012674F5793870D9C8D5CF_1EBA1750_2E771.jpg')">
          <img :src="require('../../../assets/C79EBE8A3CF918A2253B69334D6_5128A1B7_2E967.jpg')">
        </div>
        <div class="immp-item">
          <img :src="require('../../../assets/392B2CA2B5F301EBDE82453C12B_7049AE22_43023.jpg')">
          <img :src="require('../../../assets/A4B265701617AE2CA7334422DAF_67CAE7AF_4246E.jpg')">
        </div>
      </div>
      <h1>2. 中核集团合格供应商</h1>
      <div class='immp-pic'>
        <div class="immp-item">
          <img :src="require('../../../assets/9913079660D25FD6F68CD4CB020_D379CCF6_27449.jpeg')">
        </div>
      </div>
      <h1>3. ISO体系认证</h1>
      <div class='immp-pic'>
        <div class="immp-item">
          <img :src="require('../../../assets/AB185A9375C2FC2C85E62054D20_EFF4269F_2140E.jpg')">
          <img :src="require('../../../assets/98C3166FC35E4F381A60281FA34_1307EB86_2AB2A.jpg')">
        </div>
        <div class="immp-item">
          <img :src="require('../../../assets/AEE9247625A54043D7A06C30C25_846F3EA8_2B112.jpg')">
          <img :src="require('../../../assets/FB94820D85D9E97762ADDA9877D_0D048275_27AA6.jpg')">
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer/>
    </div>
  </div>
</template>

<script>
import CBanner from '../common/content/CBanner.vue'
import Footer from '../common/Footer'
export default {
  name: "abouts",
  components: {
    'CBanner': CBanner,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../../assets/show-banner.png'),
        title: "公司简介",
        txt: "面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命",
      },
    }
  }
}
</script>

<style scoped>
div#c-body {
  max-width: 1920px;
  margin: 0 auto;
}
div#content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 100px;
  color: #323232;
  text-indent: 2em;
  font-size: 14px;
  line-height: 240%;
  text-align: justify;
  text-justify: distribute;
}
div#content::after {
    content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div.footer {
  position: relative;
  top: 100px;
}
div#content > h1 {
  font-size: 21px;
  font-weight: bolder;
  color: #666;
}
div#content > div.immp-pic {
  margin: 15px auto;

}
div#content > div.immp-pic > div.immp-item {
  display: flex;
  width: 80%;
  -ms-flex-align: center;
  -ms-flex-item-align: center;
  margin: 0 auto;
    text-align: center;
  justify-content: center;
  align-items: center;
}
div#content > div.immp-pic > div.immp-item > img {
  display: flex;
  width: 50%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 50px;
}
</style>
